import jsPDF from 'jspdf';
import 'jspdf-autotable';
import React from 'react';
import { GrDocumentPdf } from "react-icons/gr";
import Grid from '../components/Grid';
import { DateInput, GroupInput, Input, TextInput } from '../components/Input';
import { useSystemContext } from '../Context/SystemContext';
import { dateToString } from '../helper/dates';

const yup = require('../interface/yup-br');
const moment = require('moment');


export const LicitaDocumento = () => {
    const System = useSystemContext();

    const dataPorExtenso = (transformdata) => {
        const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
        const dia = transformdata.getDate();
        const mes = meses[transformdata.getMonth()];
        const ano = transformdata.getFullYear();
        return `${dia} de ${mes} de ${ano}`;
    };


    const gerarPDF = async (data) => {
        const doc = new jsPDF();

        // Buscar logo da empresa
        const response = await System.apiGet('/company/' + System.property.user.companyId);
        const company = response.data;
        const logo = await System.methods.apiGetImg(company.logotipo);
        // Adicionar o quadro com subdivisões
        const quadroWidth = doc.internal.pageSize.width - 20;
        let top = 40;
        let lines = '';
        let entrelinhas = 6;
        doc.setDrawColor(0);

        // Logo
        doc.addImage(logo, 'JPEG', doc.internal.pageSize.width - 55, 5, 30, 10);

        // Nome  Titulo
        doc.setFontSize(17);
        const titleLines = doc.splitTextToSize(data.nome, quadroWidth - 20);
        titleLines.forEach((line, index) => {
            doc.text(line, doc.internal.pageSize.width / 2, top + (index * 5), { align: 'center' });
        });
        doc.setFontSize(10);


        // orgao
        top += 30;
        let identific = 'À ' + data.orgao + '.';
        lines = doc.splitTextToSize(identific, quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += entrelinhas));
        });

        // numero
        top += 10;
        lines = doc.splitTextToSize(data.identificacao, quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += entrelinhas));
        });

        // objeto
        top += 10;
        lines = doc.splitTextToSize(data.objeto, quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += entrelinhas));
        });

        // Descricao
        top += 10;
        lines = doc.splitTextToSize(data.descricao, quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, 12, (top += entrelinhas));
        });

        // cidade e data
        top += 10;
        let rodapedata = `${company.cidade}/${company.estado}, ${dataPorExtenso(new Date())}.`;
        doc.text(rodapedata, quadroWidth - 10, (top += entrelinhas), { align: 'right' });

        // rodape
        top += 20;
        lines = doc.splitTextToSize(data.rodape, quadroWidth - 10);
        lines.forEach((line) => {
            doc.text(line, doc.internal.pageSize.width / 2, (top += entrelinhas), { align: 'center' });
        });


        // Adicionar o rodapé
        //addFooter(doc);

        // Salve o PDF
        doc.save(`${data.nome}.pdf`);

    }

    return (
        <Grid
            url={'/licitadocumento'}
            perms={'45'}
            disableModal={true}
            titlePDF="Documentos padrão"
            GridHeaders={[
                { title: 'Documento customizável', field: 'nome', sort: true, className: 'w-11/12' },
                { title: 'Elaboração', field: 'elaboracao', type: 'date', sort: true, className: 'w-1/12' },
            ]}
            LineDataPrint={(data) => {
                return [
                    data.nome,
                    dateToString(data.elaboracao),
                ];
            }}
            initialValues={{
                elaboracao: moment().format('YYYY-MM-DD')
            }}
            onRenderForm={(GridModes, SetFieldValue, values) => {
                localStorage.setItem('autoEdit', null);
                return (
                    <>
                        <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                            <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                        </div>
                        <div className='p-2'>
                            <GroupInput>
                                <Input name="nome" label="Nome" />
                                <DateInput name="elaboracao" label="Elaboracao" className="w-min" />
                            </GroupInput>
                            <GroupInput>
                                <Input name="orgao" label="Orgão" />
                            </GroupInput>
                            <GroupInput>
                                <Input name="identificacao" label="Pregão / Processo adminitrativo nr." />
                            </GroupInput>
                            <GroupInput>
                                <TextInput name="objeto" label="Objeto / Escopo do Edital" rows={'5'} />
                            </GroupInput>
                            <GroupInput>
                                <TextInput name="descricao" label="Descrição da declaração" rows={'5'} />
                            </GroupInput>
                            <GroupInput>
                                <TextInput name="rodape" label="Descrição de rodapé" rows={'5'} centralized />
                            </GroupInput>
                            <GroupInput>
                                <button className="px-4 py-2 mt-4 ml-2 rounded bg-amarelo-eq "
                                    onClick={() => gerarPDF(values)}
                                >
                                    <span className='inline-flex'><GrDocumentPdf size={12} className='mt-1 mr-2' />Gerar documento em PDF</span>
                                </button>
                            </GroupInput>
                        </div>
                    </>
                );
            }}
            validationSchema={
                yup.object().shape({
                    nome: yup.string().required('Informe a descrição'),
                })
            }
        />

    );
}