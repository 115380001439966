import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { BiMenu } from 'react-icons/bi';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useSystemContext } from '../Context/SystemContext';
import Layout from '../Layout/Layout';
import noImage from '../assets/no-image.png';
import Grid from '../components/Grid';
import { BasicInput, CepInput, CheckBoxInput, DBSelectInput, DateInput, FormInput, GroupInput, Input, MaskInput, SelectInput } from '../components/Input';
import { showConfirm } from '../components/Messages';
import { dateToString } from '../helper/dates';
import { CompanySchema } from '../validation/CompanySchema';

import { companyMenu } from './Database';


const yup = require('../interface/yup-br');

const CadastroEmpresa = () => {
    const System = useSystemContext();
    let navigate = useNavigate();
    const [Menu, setMenu] = React.useState(1);
    const [company, setCompany] = React.useState(null);
    const [ImagemEmpresa, setImagemEmpresa] = React.useState(noImage);

    useEffect(() => {
        (async () => {
            try {
                let c = await System.property.company;
                setCompany(c);
                setImagemEmpresa(await System.methods.apiGetImg(c.logotipo));
            } catch (error) {
                console.log(error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    async function handleSubmit(values) {
        var response = null;
        response = await System.apiFormPut('/company/' + System.property.user.companyId, values);
        if (response.status === 200) {
            await System.methods.refreshToken();
            setCompany(values);
            System.methods.notificationSuccess(response.data.message);
            navigate('/empresa', { replace: false });
        } else {
            System.methods.notificationError(response.data.message);
        }
        return false;
    }
    if (System.property.user.level < 2) {
        return <div>Sem Permissão</div>;
    }
    return (
        <Layout>
            <div className="min-h-screen grow-0 bg-yellow-50">
                {MenuEsquerdo()}
                {MenuDashBoardGenerator()}
            </div>
            <div className="grow bg-yellow-50">
                {Menu === 1 && TelaEmpresa()}
                {Menu === 2 && TelaFuncoes()}
                {Menu === 3 && TelaFuncionario()}
                {Menu === 4 && TelaAreas()}
                {Menu === 5 && TelaUnidadeNegocio()}
                {Menu === 6 && TelaPLataformaBusca()}
                {Menu === 7 && TelaPLataformaOperacao()}

                {Menu === 301 && RebuildDashboard()}
            </div>
            <div className="grow"></div>
        </Layout>
    );

    function DrawItemMenu(numero, dsc) {
        return (
            <div className={`w-full cursor-pointer hover:bg-gray-300 ${Menu === numero ? 'bg-gray-200' : 'bg-white'}`} onClick={() => setMenu(numero)}>
                {dsc}
            </div>
        );
    }


    function MenuEsquerdo() {
        return (
            <div className="ml-2 rounded w-72 bg-yellow-50">
                <div className="inline-flex w-full pl-2 mt-4 text-xl rounded-t h-7 bg-fundo-grupo-menu-lateral text-fonte-grupo-menu-lateral">
                    <BiMenu className="mt-1 mr-1" /> Configuração
                </div>
                <div className="text-left border-b border-l border-r">
                    {DrawItemMenu(1, '1 - Informações da Empresa')}
                    {DrawItemMenu(2, '2 - Funções')}
                    <div
                        className={`w-full cursor-pointer hover:bg-gray-300 ${Menu === 3 ? 'bg-gray-200' : 'bg-white'}`}
                        onClick={() => {
                            // remove o cache para forçar atualizacao
                            localStorage.removeItem('cache_crew');
                            localStorage.removeItem('cache_crewactive');
                            setMenu(3);
                        }}
                    >
                        3 - Colaboradores
                    </div>
                    {DrawItemMenu(4, '4 - Áreas da empresa')}
                    {DrawItemMenu(5, '5 - Unidade de negócio')}
                </div>
            </div>
        );
    }
    // { DrawItemMenu(6, '6 - Plataformas de Busca') }
    // { DrawItemMenu(7, '7 - Plataformas de Operação') }


    function MenuDashBoardGenerator() {
        return null;
        /*
        return (
            <div className="ml-2 rounded w-72 bg-yellow-50">
                <div className="inline-flex w-full pl-2 mt-4 text-xl rounded-t h-7 bg-fundo-grupo-menu-lateral text-fonte-grupo-menu-lateral">
                    <BiMenu className="mt-1 mr-1" /> Dashboard
                </div>
                <div className={`w-full cursor-pointer pl-2 hover:bg-gray-300 ${Menu === 301 ? 'bg-gray-200' : 'bg-white'}`} onClick={() => setMenu(301)}>
                    • Forçar atualização
                </div>
            </div>
        );
        */
    }

    function TelaAreas() {
        return (
            <div className="mt-4 ml-2 bg-white grow">
                <Grid
                    url={'/areauser'}
                    disableModal={true}
                    GridHeaders={[
                        { title: 'Área', field: 'area', sort: true, className: 'w-full text-left pl-1' },
                    ]}
                    LineDataPrint={(data) => {
                        return [data.area];
                    }}
                    onRenderForm={(GridModes, SetFieldValue, values) => {
                        return (
                            <div>
                                <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                    <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                </div>
                                <GroupInput>
                                    <Input name="area" label="Área" />
                                </GroupInput>
                            </div>
                        );
                    }}
                />
            </div>
        );
    }
    function RebuildDashboard() {
        return (
            <div className="mt-4 ml-2 bg-white rounded grow">
                <div className="pl-2 rounded-t bg-amarelo-eq">Dashboard</div>
                <div className="pt-2 pb-2 pl-2 border shadow">
                    Atualização forçada do Dashboard.
                    <div>
                        <button
                            className="px-4 py-2 mt-2 ml-2 bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte-hover"
                            onClick={async () => {
                                showConfirm('Deseja forçar a atualização do Dashboard?', async () => {
                                    setTimeout(async () => {
                                        let response = await System.apiGet('/dashboard-total');
                                        if (response.error) {
                                            System.methods.notificationError(response.error);
                                        } else {
                                            System.methods.notificationInformation('Dashboard recarregado com sucesso.');
                                        }
                                    }, 1);
                                });
                            }}
                        >
                            Executar
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    function displayErrors(errors, touched) {
        if (touched) {
            let lst = Object.keys(errors);
            lst.map((key) => {
                if (errors[key] !== undefined) {
                    System.methods.notificationError(key + ' ' + errors[key]);
                }
                return null;
            });
        }
    }
    function TelaEmpresa() {
        return (
            <>
                <div className="mt-4 ml-2 bg-white grow">
                    <div className="pt-2 border shadow">
                        <Formik
                            _initialValues={{
                                nomeFantasia: '',
                                razaoSocial: '',
                                endereco: '',
                                numero: '',
                                bairro: '',
                                cep: '',
                                cidade: '',
                                estado: '',
                                responsavel: '',
                                email: '',
                                telefone: '',
                                celular: '',
                                whatsapp: '',
                                skype: '',
                                logotipo: '',
                            }}
                            initialValues={company}
                            enableReinitialize={true}
                            validateOnChange={true}
                            validateOnBlur={true}
                            validationSchema={CompanySchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(async () => {
                                    await handleSubmit(values);
                                    setSubmitting(false);
                                }, 400);
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <GroupInput>
                                        <Input name="nomeFantasia" label="Nome Fantasia" />
                                        <Input name="razaoSocial" label="Razão Social" />
                                    </GroupInput>
                                    <GroupInput>
                                        <CepInput
                                            name="cep"
                                            label="CEP"
                                            className="w-44"
                                            onReceivedCep={(received) => {
                                                setFieldValue('endereco', received.logradouro);
                                                setFieldValue('cidade', received.localidade);
                                                setFieldValue('estado', received.uf);
                                                setFieldValue('bairro', received.bairro);
                                            }}
                                        />
                                        <Input name="endereco" label="Endereço" />
                                        <Input name="numero" label="Número" className="w-32" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="cidade" label="Cidade" />
                                        <MaskInput
                                            name="estado"
                                            label="Estado"
                                            className="w-48"
                                            mask="aa"
                                            beforeMaskedValueChange={function (newState) {
                                                newState.value = newState.value.toUpperCase();
                                                return newState;
                                            }}
                                        />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="bairro" label="Bairro" />
                                        <Input name="responsavel" label="Responsável" />
                                    </GroupInput>
                                    <Input name="email" label="E-mail" />
                                    <GroupInput>
                                        <MaskInput name="telefone" label="Telefone" mask="(99) 9999-9999" />
                                        <MaskInput name="celular" label="Celular" mask="(99) 9 9999-9999" />
                                        <MaskInput name="whatsapp" label="Whatsapp" mask="(99) 9 9999-9999" />
                                        <Input name="skype" label="Skype" />
                                    </GroupInput>
                                    <GroupInput>
                                        <img src={ImagemEmpresa} alt="Logotipo" style={{ maxWidth: '150px' }} className="w-40 my-2 ml-2 mr-8" />
                                        <BasicInput
                                            name="logotipo"
                                            label="Logotipo"
                                            type="file"
                                            onChange={(e) => {
                                                setFieldValue('logotipo', e.target.files[0]);
                                                //setImagemEmpresa(URL.createObjectURL(e.target.files[0]));
                                            }}
                                        />
                                    </GroupInput>
                                    <div className="flex justify-end gap-4 pt-4 pb-3 pr-4 mt-4 border-t">
                                        <button
                                            type="submit"
                                            className="w-1/3 h-8 rounded-md shadow-md text-botao-primario-fonte bg-botao-primario border-1 hover:bg-botao-primario-hover hover:text-botao-primario-fonte-hover"
                                            onClick={() => {
                                                displayErrors(errors, touched);
                                            }}
                                        >
                                            Salvar
                                        </button>
                                        <button className="w-1/3 h-8 text-black bg-gray-400 rounded-md shadow-md hover:text-gray-100" onClick={() => navigate('/')}>
                                            Cancelar
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </>
        );
    }
    function TelaFuncionario() {
        return (
            <>
                <div className="mt-4 ml-2 bg-white grow">
                    <div className="w-full border shadow">
                        <Grid
                            url={'/crew'}
                            canDelete={false}
                            defaultSortField="createdAt"
                            GridHeaders={[
                                { title: 'Id', field: 'identificacao', sort: true, className: 'w-1/12 text-left pl-1' },
                                { title: 'Nome', field: 'nome', sort: true, className: 'w-4/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Função', field: 'role', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'E-mail', field: 'mail', sort: false, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Admissão', field: 'admissao', sort: false, type: 'date', className: 'w-1/12 pl-1 text-left border-l border-r border-gray-300' },
                                { title: 'Master', field: 'master', sort: false, className: 'w-1/12 pl-1 text-center border-l border-r border-gray-300' },
                                { title: 'Status', field: 'status', sort: false, className: 'w-1/12 pl-1 text-center border-l border-r border-gray-300' },
                            ]}
                            GridTitle="Lista de Colaboradores"
                            LineDataPrint={(data) => {
                                return [
                                    data.identificacao,
                                    data.nome,
                                    data.funcao?.nome,
                                    data.email,
                                    dateToString(data.admissao),
                                    data?.master ? '#color=blue:Master' : '',
                                    data?.status,
                                ];
                            }}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                return (
                                    <div>
                                        <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                            <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                        </div>
                                        <Input name="nome" label="Nome" />
                                        <GroupInput>
                                            <Input name="identificacao" label="Identificação" />
                                            <DBSelectInput name="role" label="Função" url="/role" displaySelectField="nome" />
                                            <DateInput name="admissao" label="Admissão" />
                                        </GroupInput>
                                        <GroupInput>
                                            <DBSelectInput name="unidadenegocioid" label="Unidade de Negócio" url="/unidadenegocio" displaySelectField="unidade" className="w-1/2" />
                                        </GroupInput>
                                        <div className={'ml-2 mt-4 bg-amarelo-eq'}>Acesso ao Sistema</div>
                                        <GroupInput>
                                            <Input name="email" label="Email" type="email" className={'w-4/6'} />
                                            <Input name="senha" label="Senha" className={'w-2/6'} />
                                        </GroupInput>
                                        <GroupInput>
                                            <SelectInput
                                                name="status"
                                                label="Status colaborador"
                                                className={'inline-block w-1/3'}
                                                listOptions={[{ value: 'Inativo' }, { value: 'Ativo' }]}
                                            />
                                            <div className="px-2 pt-3 mt-2 ml-4 bg-yellow-200 border rounded shadow">
                                                <CheckBoxInput name="master" label="Usuário master" />
                                            </div>
                                        </GroupInput>
                                    </div>
                                );
                            }}
                            validationSchema={yup.object().shape({
                                nome: yup.string().required('Nome é obrigatório'),
                                identificacao: yup.string().required('Identificação é obrigatório'),
                                role: yup.string().required('Função é obrigatório'),
                                admissao: yup.string().required('Admissão é obrigatório'),
                                email: yup.string(),
                                senha: yup.string(),
                                status: yup.string().required('Status é obrigatório'),
                                master: yup.boolean(),
                            })}
                        />
                    </div>
                </div>
            </>
        );
    }

    function TelaFuncoes() {
        let menu_json = companyMenu();
        let permissoes = [];
        Object.entries(menu_json).map(([key, value]) => {
            let clickSelf = null;
            if (value.hasOwnProperty('self')) {
                clickSelf = value.self;
            }
            if (clickSelf) {
                permissoes[permissoes.length] = { id: clickSelf, dsc: `${value.menu}` };
            }
            return value.submenu.map((sub) => {
                let [id, dsc] = Object.entries(sub)[0];
                if (System.property.user.companyNorma === 'RDC-665') {
                    permissoes[permissoes.length] = { id: id, dsc: `${key} - ${dsc}` };
                } else {
                    permissoes[permissoes.length] = { id: id, dsc: dsc };
                }
                return true;
            });
        });

        function btnFunc(setFieldValue, value) {
            permissoes.map((item) => {
                setFieldValue(`menu${item.id}`, value);
                return true;
            });
        }
        function btnFuncAlterar(setFieldValue, value) {
            permissoes.map((item) => {
                if (value) setFieldValue(`menu${item.id}`, value);
                setFieldValue(`alterar${item.id}`, value);
                return true;
            });
        }
        function btnFuncDeletar(setFieldValue, value) {
            permissoes.map((item) => {
                if (value) setFieldValue(`menu${item.id}`, value);
                if (value) setFieldValue(`alterar${item.id}`, value);
                setFieldValue(`deletar${item.id}`, value);
                return true;
            });
        }
        function lineGrid(item, setFieldValue) {
            return (
                <div className="flex border-gray-200 rounded">
                    <div className="flex w-1/12 text-center border-t border-l">
                        <CheckBoxInput name={`menu${item.id}`} />
                    </div>
                    <div className="flex w-1/12 text-center border-t border-l">
                        <CheckBoxInput
                            name={`alterar${item.id}`}
                            onChange={(e) => {
                                if (e.target.checked) setFieldValue(`menu${item.id}`, true);
                                setFieldValue(`alterar${item.id}`, e.target.checked);
                            }}
                        />
                    </div>
                    <div className="flex w-1/12 text-center border-t border-l">
                        <CheckBoxInput
                            name={`deletar${item.id}`}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setFieldValue(`menu${item.id}`, true);
                                    setFieldValue(`alterar${item.id}`, true);
                                }
                                setFieldValue(`deletar${item.id}`, e.target.checked);
                            }}
                        />
                    </div>
                    <div className="flex w-9/12 border-t border-l border-r">
                        <span className="w-full ml-2">{item.dsc}</span>
                    </div>
                </div>
            );
        }

        return (
            <>
                <div className="mt-4 ml-2 bg-white rounded grow">
                    <div className="w-full border shadow">
                        <Grid
                            url={'/role/' + System.property.company._id}
                            GridTitle="Funções dos Colaboradores"
                            canDelete={false}
                            GridHeaders={[{ title: 'Nome da Função', field: 'nome', sort: true, className: 'w-full text-left pl-1' }]}
                            disableModal={true}
                            LineDataPrint={(data) => {
                                return [data.nome];
                            }}
                            validationSchema={yup.object().shape({
                                nome: yup.string().required('Nome da Função é obrigatória'),
                            })}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                return (
                                    <div>
                                        <GroupInput>
                                            <Input name="nome" label="Nome da função" />
                                        </GroupInput>
                                    </div>
                                );
                            }}
                            FormTabs={[
                                () => {
                                    return <div>Home</div>;
                                },
                                () => {
                                    return <div>Permissões</div>;
                                },
                            ]}
                            onRenderFormExtra={[
                                (GridModes, item, GridRefresh) => {
                                    // Permissões
                                    return (
                                        <div>
                                            <FormInput
                                                document={`/role/${System.property.company._id}/${item._id}`}
                                                validationSchema={yup.object().shape({
                                                    nome: yup.string(),
                                                })}
                                                initialValues={{
                                                    nome: '',
                                                }}
                                                onRenderFields={(setFieldValue, values) => {
                                                    return (
                                                        <div key={uuid()}>
                                                            <div className="container mx-auto mt-4 ml-2 mr-2">
                                                                <div className="flex border-gray-200">
                                                                    <div className="flex w-1/12 bg-gray-100 border-t border-l">
                                                                        <span className="w-full text-center">Consultar</span>
                                                                    </div>
                                                                    <div className="flex w-1/12 bg-gray-100 border-t border-l">
                                                                        <span className="w-full text-center">Alterar</span>
                                                                    </div>
                                                                    <div className="flex w-1/12 bg-gray-100 border-t border-l">
                                                                        <span className="w-full text-center">Deletar</span>
                                                                    </div>
                                                                    <div className="flex w-9/12 text-left bg-gray-100 border-t border-l border-r">
                                                                        <span className="w-full ml-2">Descrição</span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex border-gray-200 rounded" key={uuid()}>
                                                                    <div className="flex justify-center w-1/12 border-t border-l">
                                                                        <ImCheckboxChecked
                                                                            className="mr-1 text-sm hover:cursor-pointer hover:text-blue-700"
                                                                            title="Marcar todos"
                                                                            onClick={() => btnFunc(setFieldValue, true)}
                                                                        />
                                                                        <ImCheckboxUnchecked
                                                                            className="mr-1 text-sm hover:cursor-pointer hover:text-red-700"
                                                                            title="Desmarcar todos"
                                                                            onClick={() => btnFunc(setFieldValue, false)}
                                                                        />
                                                                    </div>
                                                                    <div className="flex justify-center w-1/12 border-t border-l">
                                                                        <ImCheckboxChecked
                                                                            className="mr-1 text-sm hover:cursor-pointer hover:text-blue-700"
                                                                            title="Marcar todos"
                                                                            onClick={() => btnFuncAlterar(setFieldValue, true)}
                                                                        />
                                                                        <ImCheckboxUnchecked
                                                                            className="mr-1 text-sm hover:cursor-pointer hover:text-red-700"
                                                                            title="Desmarcar todos"
                                                                            onClick={() => btnFuncAlterar(setFieldValue, false)}
                                                                        />
                                                                    </div>
                                                                    <div className="flex justify-center w-1/12 border-t border-l">
                                                                        <ImCheckboxChecked
                                                                            className="mr-1 text-sm hover:cursor-pointer hover:text-blue-700"
                                                                            title="Marcar todos"
                                                                            onClick={() => btnFuncDeletar(setFieldValue, true)}
                                                                        />
                                                                        <ImCheckboxUnchecked
                                                                            className="mr-1 text-sm hover:cursor-pointer hover:text-red-700"
                                                                            title="Desmarcar todos"
                                                                            onClick={() => btnFuncDeletar(setFieldValue, false)}
                                                                        />
                                                                    </div>
                                                                    <div className="flex w-9/12 border-t border-l"></div>
                                                                </div>

                                                                {permissoes.map((item) => {
                                                                    return lineGrid(item, setFieldValue);
                                                                })}
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                },
                            ]}
                        />
                    </div>
                </div>
            </>
        );
    }

    function TelaUnidadeNegocio() {
        return (
            <>
                <div className="mt-4 ml-2 bg-white grow">
                    <div className="w-full border shadow">
                        <Grid
                            url={'/unidadenegocio'}
                            canDelete={false}
                            GridHeaders={[
                                { title: 'Unidade', field: 'unidade', sort: true, className: 'w-full text-left pl-1' },
                            ]}
                            GridTitle="Unidades de negócio"
                            LineDataPrint={(data) => {
                                return [
                                    data.unidade
                                ];
                            }}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                return (
                                    <div>
                                        <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                            <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                        </div>
                                        <Input name="unidade" label="Unidade" />
                                    </div>
                                );
                            }}
                            validationSchema={yup.object().shape({
                                unidade: yup.string().required('Unidade é obrigatório'),
                            })}
                        />
                    </div>
                </div>
            </>
        );
    }

    function TelaPLataformaBusca() {
        return (
            <div className="mt-4 ml-2 bg-white grow">
                <div className="w-full border shadow">
                    <Grid
                        url={'/licitabusca'}
                        disableModal={true}
                        GridHeaders={[
                            { title: 'Nome', field: 'nome', sort: true, className: 'w-10/12' },
                            { title: 'Vencimento', field: 'vencimento', type: 'date', sort: true, className: 'w-1/12' },
                            { title: 'Ativo', field: 'ativo', sort: true, className: 'w-1/12 text-left pl-1' },
                        ]}
                        GridTitle="Plataformas de busca"
                        LineDataPrint={(data) => {
                            return [
                                data.nome,
                                dateToString(data.vencimento),
                                data.ativo ? '🟢 Ativo' : '🔴 Inativo',
                            ];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="nome" label="Nome" />
                                        <Input name="url" label="URL" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="vencimento" label="Vencimento" className={'w-min'} />
                                        <SelectInput
                                            name="ativo"
                                            label="Status"
                                            listOptions={[
                                                { option: 'Ativo', value: true },
                                                { option: 'Inativo', value: false },
                                            ]}
                                            className={'w-48'}
                                        />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            nome: yup.string().required('Nome é obrigatório'),
                            vencimento: yup.date().required('Vencimento inválido'),
                        })}
                    />
                </div>
            </div>
        );
    }

    function TelaPLataformaOperacao() {
        return (
            <div className="mt-4 ml-2 bg-white grow">
                <div className="w-full border shadow">
                    <Grid
                        url={'/licitaoperacao'}
                        disableModal={true}
                        GridHeaders={[
                            { title: 'Nome', field: 'nome', sort: true, className: 'w-10/12' },
                            { title: 'Vencimento', field: 'vencimento', type: 'date', sort: true, className: 'w-1/12' },
                            { title: 'Ativo', field: 'ativo', sort: true, className: 'w-1/12 text-left pl-1' },
                        ]}
                        GridTitle="Plataformas de operação"
                        LineDataPrint={(data) => {
                            return [
                                data.nome,
                                dateToString(data.vencimento),
                                data.ativo ? '🟢 Ativo' : '🔴 Inativo',
                            ];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="nome" label="Nome" />
                                        <Input name="url" label="URL" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="vencimento" label="Vencimento" className={'w-min'} />
                                        <SelectInput
                                            name="ativo"
                                            label="Status"
                                            listOptions={[
                                                { option: 'Ativo', value: true },
                                                { option: 'Inativo', value: false },
                                            ]}
                                            className={'w-48'}
                                        />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            nome: yup.string().required('Nome é obrigatório'),
                            vencimento: yup.date().required('Vencimento inválido'),
                            ativo: yup.boolean(),
                        })}
                    />
                </div>
            </div>
        );
    }


};

export default CadastroEmpresa;

