import Grid from './Grid';
import { Input, SelectInput } from './Input';

const yup = require('../interface/yup-br');

export const GridChecklist = ({ url, GridHeaders, LineDataPrint, GridTitle = 'Pontos Críticos', ...rest }) => {
    let _GridHeaders = [
        { title: 'Item', field: 'item', sort: true, className: 'w-11/12 text-left pl-1' },
        { title: 'Status', field: 'status', sort: true, className: 'w-1/12 text-left pl-1' },
    ];
    return (
        <Grid
            url={url}
            {...rest}
            hideIndexColumn={true}
            disableModal={true}
            GridHeaders={GridHeaders ?? _GridHeaders}
            GridTitle={GridTitle}
            LineDataPrint={(data) => {
                return [
                    data.item,
                    data.status === 'Concluído' ? <span className="text-green-500">🟢 Concluído</span> : <span className="text-red-500">🔴 Pendente</span>,];
            }}
            onRenderForm={() => {
                return (
                    <div className="w-full">
                        <Input name="item" label="Item" />
                        <SelectInput
                            name="status"
                            label="Status"
                            className={"w-40"}
                            listOptions={[{ value: 'Concluído' }, { value: 'Pendente' }]} />
                    </div>
                );
            }}
            validationSchema={() => {
                return yup.object().shape({
                    item: yup.string(),
                });
            }}
        />
    );
};
