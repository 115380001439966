import Grid from './Grid';
import { Input } from './Input';

const yup = require('../interface/yup-br');

export const GridPontosCriticos = ({ url, GridHeaders, LineDataPrint, GridTitle = 'Pontos Críticos', ...rest }) => {
    let _GridHeaders = [
        { title: 'Item', field: 'item', sort: true, className: 'w-full text-left pl-1' },
    ];
    let _LineDataPrint = (data) => {
        return [data.item];
    };
    return (
        <Grid
            url={url}
            {...rest}
            hideIndexColumn={true}
            disableModal={true}
            GridHeaders={GridHeaders ?? _GridHeaders}
            GridTitle={GridTitle}
            LineDataPrint={LineDataPrint ?? _LineDataPrint}
            onRenderForm={() => {
                return (
                    <div className="w-full">
                        <Input name="item" label="Item" />
                    </div>
                );
            }}
            validationSchema={() => {
                return yup.object().shape({
                    item: yup.string(),
                });
            }}
        />
    );
};
