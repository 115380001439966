import moment from 'moment';

export const dateToString = (stringDate) => {
    if (!stringDate) return null;
    return moment.utc(stringDate).format('DD/MM/YYYY');
};
export const dateToStringFull = (stringDate) => {
    if (!stringDate) return null;

    // Criando um objeto Date a partir da string de entrada
    const date = new Date(stringDate);

    // Diminuindo 3 horas
    //date.setHours(date.getHours() - 3);

    // Formatando a data como DD/MM/YYYY HH:mm
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 porque getMonth() retorna de 0-11
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
};

// adiciona dias a uma data e retorna string formatada
export const AdicionarDias = (data, dias) => moment(data).add(dias, 'days').format('YYYY-MM-DD');



