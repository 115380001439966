import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useSystemContext } from '../Context/SystemContext';
import { GroupInput, Input } from '../components/Input';
import '../styles.css';

import logoLicitaGestao from '../assets/login_logo.png';

const yup = require('../interface/yup-br');

const schema = yup.object().shape({
    email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
    password: yup.string().required('Senha obrigatória'),
});

export default function Login() {
    const { login } = useSystemContext();
    const [message, setMessage] = useState('');

    async function handleLogin(values) {
        let response = await login(values);
        if (response.status === 200) {
            window.location.href = '/';
        } else {
            console.log('@@@@', response);
            setMessage('Usuário ou senha inválidos');
            localStorage.removeItem('token');
        }
    }
    return (
        <section
            className="flex items-center w-screen h-screen text-white bg-white"
        >
            <div className="p-10 mx-auto border border-gray-800 rounded shadow w-72 bg-opacity-80"
                style={{ backgroundImage: `url(${require('../assets/fundo_login.png')})`, backgroundSize: 'cover' }}
            >
                <div className="py-2 mx-auto">
                    <div className='flex justify-center'>
                        <img src={logoLicitaGestao} alt="logo" height={180} width={180} />
                    </div>
                </div>
                <div className="py-2 text-2xl text-center ">Login</div>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            handleLogin(values);
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    <Form>
                        <div>
                            <GroupInput>
                                <Input name="email" type="email" label="Email" className="text-black" labelClassName="text-white" />
                            </GroupInput>
                            <GroupInput>
                                <Input name="password" type="password" label="Senha" className="text-black" labelClassName="text-white" />
                            </GroupInput>
                        </div>
                        <div className="w-full mt-8 text-center text-gray-800">
                            <button
                                type="submit"
                                className="w-3/4 py-2 bg-gray-300 border rounded shadow-md text-botao-primario-fonte hover:bg-gray-700 hover:text-white"
                            >
                                Enviar
                            </button>
                        </div>
                        <div className="w-full text-center text-red-500">{message}</div>
                    </Form>
                </Formik>
            </div>
        </section>
    );
}
