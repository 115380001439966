const API_URL_LOCAL = 'http://localhost:3010';
const API_URL_PROD = 'https://api.licitagestao.com.br:3010';

const LOGO = 'logo-escola.png';

export const Config = () => {
    function getApiUrl() {
        let apiUrl = API_URL_PROD;
        if (window.location.href.indexOf('localhost') !== -1 || window.location.href.indexOf('192.168.0') !== -1) {
            apiUrl = API_URL_LOCAL;
        }
        return apiUrl;
    }

    return {
        apiUrl: getApiUrl(),
        logo: LOGO,
    };
};
