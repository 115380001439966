import React, { useRef, useState } from 'react';
import { BiMenu } from 'react-icons/bi';
import { v4 as uuid } from 'uuid';
import { useSystemContext } from '../Context/SystemContext';
import Layout from '../Layout/Layout';
import noImage from '../assets/no-image.png';
import Grid from '../components/Grid';
import { AttachmentInput, CheckBoxInput, DBSelectInput, GroupInput, Input, MaskInput, SelectInput } from '../components/Input';
const yup = require('../interface/yup-br');

const SystemAdminPage = () => {
    const System = useSystemContext();
    const [Menu, setMenu] = useState(1);
    const logoConsultorRef = useRef(null);
    return (
        <Layout>
            <div className="min-h-screen mt-2 ml-2 grow-0">{MenuAdmin()}</div>
            <div className="w-2 grow-0 "></div>
            <div className="pt-2 grow">
                {Menu === 1 && TelaAdminEmpresas()}
                {Menu === 2 && TelaAdminUsers()}
                {Menu === 3 && CadastroConsultor()}
                {Menu === 4 && CadastroAdmins()}
            </div>
        </Layout>
    );
    function MenuAdmin() {
        function ItemMenu(itemID, text, subitem = false) {
            return (
                <div onClick={() => setMenu(itemID)} className={`cursor-pointer pl-4 hover:bg-gray-300 ${Menu === itemID ? 'bg-gray-200' : 'bg-white'} ${subitem ? 'ml-2' : null}`}>
                    {text}
                </div>
            );
        }
        return (
            <div className="rounded w-96 ">
                <div className="inline-flex w-full pl-2 text-xl rounded-t h-7 bg-amarelo-eq">
                    <BiMenu className="mt-1 mr-1" /> Administração
                </div>
                <div className="pl-2 text-left bg-white border-b border-l border-r">
                    <div className={`w-full`}>Cadastros</div>
                    <div>
                        {ItemMenu(1, 'Empresas')}
                        {ItemMenu(3, 'Consultores')}
                        {ItemMenu(4, 'Administradores')}
                    </div>
                </div>
            </div>
        );
    }
    function TelaAdminUsers() {
        return (
            <div className="mr-2 bg-white">
                <Grid
                    url={'/crew'}
                    url_post={'/crewmaster'}
                    filter={{ user: { level: 5 } }}
                    GridHeaders={[
                        {
                            title: 'Identificação',
                            field: 'identificacao',
                            sort: true,
                            className: 'w-1/12 text-left pl-1',
                        },
                        {
                            title: 'Nome',
                            field: 'nome',
                            sort: true,
                            className: 'w-6/12 pl-1 text-left border-l border-gray-300',
                        },
                        {
                            title: 'E-mail',
                            field: 'email',
                            sort: true,
                            className: 'w-4/12 pl-1 text-left border-l border-gray-300',
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            sort: false,
                            className: 'w-1/12 pl-1 text-left border-l border-r border-gray-300',
                        },
                    ]}
                    GridTitle="Lista de Colaboradores"
                    LineDataPrint={(data) => {
                        if (data?.user?.level !== 5) return null;
                        return [data.identificacao, data.nome, data.email, data?.status];
                    }}
                    onRenderForm={(GridModes, SetFieldValue, values) => {
                        return (
                            <div>
                                <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                    <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                </div>
                                <GroupInput>
                                    <Input name="identificacao" label="Identificação" className={'w-1/3'} />
                                    <Input name="nome" label="Nome" />
                                </GroupInput>
                                <div className={'ml-2 mt-4 bg-amarelo-eq'}>Acesso ao Sistema</div>
                                <GroupInput>
                                    <Input name="email" label="Email" type="email" className={'w-4/6'} />
                                    <Input name="senha" label="Senha" className={'w-2/6'} />
                                </GroupInput>
                                <SelectInput name="status" label="Status colaborador" className={'inline-block'} listOptions={[{ value: 'Inativo' }, { value: 'Ativo' }]} />
                            </div>
                        );
                    }}
                    validationSchema={yup.object().shape({
                        nome: yup.string().required('Nome é obrigatório'),
                        identificacao: yup.string().required('Identificação é obrigatório'),
                        email: yup.string().required('Email é obrigatório'),
                        senha: yup.string().required('Senha é obrigatório'),
                        status: yup.string().required('Status é obrigatório'),
                    })}
                />
            </div>
        );
    }
    function CadastroConsultor(logotipo) {
        return (
            <>
                <div className="bg-white grow">
                    <div className="w-full border shadow">
                        <Grid
                            url={'/user'}
                            filter={{ level: 10 }}
                            key={uuid()}
                            GridHeaders={[
                                {
                                    title: 'Nome',
                                    field: 'name',
                                    sort: true,
                                    className: 'w-6/12 text-left pl-1',
                                },
                                {
                                    title: 'E-mail',
                                    field: 'email',
                                    sort: true,
                                    className: 'w-3/12 pl-1 text-left border-l border-gray-300',
                                },
                                {
                                    title: 'Whatsapp',
                                    field: 'whatsapp',
                                    sort: true,
                                    className: 'w-2/12 pl-1 text-center border-l border-gray-300',
                                },
                                {
                                    title: 'Suporte',
                                    field: 'suporte',
                                    sort: true,
                                    className: 'w-1/12 pl-1 text-center border-l border-gray-300',
                                },
                            ]}
                            GridTitle="Cadastro de Consultores"
                            LineDataPrint={(item) => {
                                return [item.name, item.email, item.whatsapp, item.suporte ? '#color=red:Sim' : 'Não'];
                            }}
                            initialValues={{
                                name: '',
                                email: '',
                                password: '',
                                passwordConfirm: '',
                                level: 10,
                            }}
                            onPrepareSaveRecord={(values) => {
                                values.level = 10;
                                if (values.arquivo?.name) {
                                    let newForm = new FormData();
                                    for (let key in values) {
                                        newForm.append(key, values[key]);
                                    }
                                    return newForm;
                                }
                                return values;
                            }}
                            validationSchema={yup.object().shape({
                                name: yup.string().required('Nome é obrigatório'),
                                email: yup.string().email('Email inválido').required('Email é obrigatório'),
                                whatsapp: yup.string(),
                                logotipo: yup.string(),
                                usarlogotipo: yup.boolean(),
                                password: yup.string().required('Senha é obrigatória').min(6, 'Senha deve ter no mínimo 6 caracteres'),
                                passwordConfirm: yup
                                    .string()
                                    .required('Confirmação de senha é obrigatória')
                                    .oneOf([yup.ref('password'), null], 'Senhas não conferem'),
                            })}
                            onRenderForm={(GridModes, setFieldValue, values, GridRefresh) => {
                                if (logoConsultorRef && values?.logotipo !== null && values?.logotipo !== undefined) {
                                    (async () => {
                                        let l = await System.methods.apiGetImg(values.logotipo);
                                        logoConsultorRef.current.src = l || noImage;
                                    })();
                                }
                                return (
                                    <>
                                        <Input name="name" label="Nome" className="w-92" />
                                        <Input name="email" label="Email" className="w-92" type="email" />
                                        <GroupInput key={uuid()}>
                                            <MaskInput name="whatsapp" label="WhatsApp Suporte" mask="(99) 9 9999-9999" className={'w-1/2'} />
                                            <div className="px-2 pt-4 mt-3 ml-4">
                                                <CheckBoxInput name="suporte" label="Redirecionar suporte para este consultor" />
                                            </div>
                                        </GroupInput>
                                        <GroupInput>
                                            <img
                                                src={noImage}
                                                alt="Logotipo"
                                                ref={logoConsultorRef}
                                                title="Logotipo (120 x 53)"
                                                style={{
                                                    maxWidth: '120px',
                                                    maxHeight: '53px',
                                                }}
                                                className="w-40 my-2 ml-2 mr-8"
                                            />
                                            <AttachmentInput name="logotipo" label="Arquivo de Logotipo" className="w-1/2" />
                                        </GroupInput>
                                        <CheckBoxInput name="usarlogotipo" label="Usar Logotipo do consultor no sistema" />
                                        <GroupInput className="mt-2">
                                            <Input name="password" label="Senha" type="password" />
                                            <Input name="passwordConfirm" label="Confirme" type="password" />
                                        </GroupInput>
                                    </>
                                );
                            }}
                            FormTabs={[
                                () => {
                                    return <div>Consultor</div>;
                                },
                                () => {
                                    return <div>Empresas</div>;
                                },
                            ]}
                            onRenderFormExtra={[
                                (GridModes, item, GridRefresh) => {
                                    return (
                                        <div>
                                            {!GridModes.insert_mode && (
                                                <div className={'m-2'}>
                                                    <Grid
                                                        url={`/consulant/${item._id}`}
                                                        disableModal={true}
                                                        GridHeaders={[
                                                            {
                                                                title: 'Nome Fantasia',
                                                                field: 'nomeFantasia',
                                                                sort: true,
                                                                className: 'w-1/2 text-left pl-1',
                                                            },
                                                            {
                                                                title: 'Razão Social',
                                                                field: 'razaoSocial',
                                                                sort: true,
                                                                className: 'w-1/2 pl-1 text-left border-l border-gray-300',
                                                            },
                                                        ]}
                                                        GridTitle="Empresas vinculadas ao Consultor"
                                                        LineDataPrint={(data) => {
                                                            return [data.company?.nomeFantasia, data.company?.razaoSocial];
                                                        }}
                                                        onRenderForm={() => {
                                                            return (
                                                                <div className="w-full">
                                                                    <DBSelectInput name="company_id" label="Empresa" url="/company" displaySelectField="nomeFantasia" />
                                                                </div>
                                                            );
                                                        }}
                                                        validationSchema={() => {
                                                            return yup.object().shape({
                                                                company_id: yup.string(),
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                },
                            ]}
                        />
                    </div>
                </div>
            </>
        );
    }
    function CadastroAdmins() {
        return (
            <>
                <div className="bg-white grow">
                    <div className="w-full border shadow">
                        <Grid
                            url={'/user'}
                            filter={{ level: 99 }}
                            GridHeaders={[
                                {
                                    title: 'Nome',
                                    field: 'name',
                                    sort: true,
                                    className: 'w-1/2 text-left pl-1',
                                },
                                {
                                    title: 'E-mail',
                                    field: 'email',
                                    sort: true,
                                    className: 'w-1/2 pl-1 text-left border-l border-gray-300',
                                },
                            ]}
                            GridTitle="Cadastro de Administradores"
                            LineDataPrint={(item) => {
                                return [item.name, item.email];
                            }}
                            onPrepareSaveRecord={(values) => {
                                values.level = 99;
                                if (values.arquivo?.name) {
                                    let newForm = new FormData();
                                    for (let key in values) {
                                        newForm.append(key, values[key]);
                                    }
                                    return newForm;
                                }
                                return values;
                            }}
                            onRenderForm={(GridModes) => {
                                return (
                                    <>
                                        <Input name="name" label="Nome" className="w-92" />
                                        <Input name="email" label="Email" className="w-92" type="email" />
                                        <GroupInput>
                                            <Input name="password" label="Senha" type="password" />
                                            <Input name="passwordConfirm" label="Confirme" type="password" />
                                        </GroupInput>
                                    </>
                                );
                            }}
                            initialValues={{
                                name: '',
                                email: '',
                                password: '',
                                passwordConfirm: '',
                                level: 99,
                            }}
                            validationSchema={yup.object().shape({
                                name: yup.string().required('Nome é obrigatório'),
                                email: yup.string().email('Email inválido').required('Email é obrigatório'),
                                password: yup.string().required('Senha é obrigatória').min(6, 'Senha deve ter no mínimo 6 caracteres'),
                                passwordConfirm: yup
                                    .string()
                                    .required('Confirmação de senha é obrigatória')
                                    .oneOf([yup.ref('password'), null], 'Senhas não conferem'),
                            })}
                        />
                    </div>
                </div>
            </>
        );
    }
    function TelaAdminEmpresas() {
        function MudaStatusEmpresa(GridData, id) {
            GridData.forEach((item) => {
                if (item._id === id) {
                    item.ativa = !item.ativa;
                    System.apiPut(`/company-status/${id}`, { ativa: item.ativa }).then((res) => {
                        if (res.status === 200) {
                            System.methods.notificationSuccess('Status da empresa alterado!');
                        }
                    });
                }
            });
            return false;
        }
        return (
            <>
                <div className="bg-white grow">
                    <div className="w-full border shadow">
                        <Grid
                            GridHooks={{
                                checkboxHook: MudaStatusEmpresa,
                            }}
                            url={'/company'}
                            canDelete={false}
                            GridHeaders={[
                                { title: 'Nome Fantasia', field: 'nomeFantasia', sort: true, className: 'w-5/12 text-left pl-1' },
                                { title: 'Razão Social', field: 'razaoSocial', sort: true, className: 'w-5/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Norma', field: 'norma', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                                { title: 'Ativa', field: 'ativa', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                            ]}
                            GridTitle="Cadastro de Empresas"
                            LineDataPrint={(item) => {
                                return [
                                    item.nomeFantasia, //
                                    item.razaoSocial, //
                                    item.norma, //
                                    `#checkbox=${item.ativa}:checkboxHook:${item._id}`, //
                                ];
                            }}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                return (
                                    <div>
                                        <div className="inline-flex w-full h-6 pl-2 justify-left bg-amarelo-eq">
                                            Cadastro de empresa
                                        </div>
                                        <Input name="nomeFantasia" label="Nome Fantasia" />
                                        <Input name="razaoSocial" label="Razão Social" />
                                        <GroupInput>
                                            <SelectInput
                                                name="ativa"
                                                label="Status da empresa"
                                                className="inline-block"
                                                defaultValue={true}
                                                listOptions={[
                                                    { value: true, option: 'Ativa' },
                                                    { value: false, option: 'Inativa' },
                                                ]}
                                            />
                                        </GroupInput>
                                    </div>
                                );
                            }}
                            validationSchema={yup.object().shape({
                                nomeFantasia: yup.string().required(),
                                razaoSocial: yup.string().required(),
                                norma: yup.string(),
                            })}
                        />
                    </div>
                </div>
            </>
        );
    }
};

export default SystemAdminPage;
