import React from 'react';
import Grid from '../components/Grid';
import { GridAnexos } from '../components/GridAnexos';
import { GridChecklist } from '../components/GridChecklist';
import { GridDiario } from '../components/GridDiario';
import { GridPontosCriticos } from '../components/GridPontosCriticos';
import { DateInput, DBSelectInput, GroupInput, Input, InputCurrency, MaskInput, SelectInput, TimeInput } from '../components/Input';
import { useSystemContext } from '../Context/SystemContext';
import { dateToString } from '../helper/dates';
const moment = require('moment');


const yup = require('../interface/yup-br');

export const translateStatus = (status) => {
    switch (status) {
        case 1:
            return 'Pendente';
        case 2:
            return 'Operação';
        case 3:
            return 'Encerrado';
        case 4:
            return 'Adjudicado';
        case 5:
            return 'Anulado';
        case 6:
            return 'Revogado';
        case 7:
            return 'Desclassificado';
        case 8:
            return 'Habilitado';
        case 9:
            return 'Recurso';
        default:
            return 'Pendente';

    }
};


export const LicitaEdital = () => {
    const System = useSystemContext();

    return (
        <div className="bg-white grow">
            <div className="w-full">
                <Grid
                    url={'/licitaedital'}
                    perms={'10'}
                    disableModal={true}
                    GridHeaders={[
                        { title: 'Data', field: 'cadastro', type: 'date', sort: true, className: 'w-1/12' },
                        { title: 'Numero', field: 'numeroLicitacao', sort: true, className: 'w-1/12' },
                        { title: 'Contratante', field: 'contratante', sort: true, className: 'w-3/12' },
                        { title: 'Objeto', field: 'objeto', sort: true, className: 'w-4/12' },
                        { title: 'Valor', field: 'valorOtiginal', sort: true, className: 'w-1/12 text-center' },
                        { title: 'Plataforma', field: 'plataformaOperacao.nome', sort: true, className: 'w-1/12' },
                        { title: 'Status', field: 'status', sort: true, className: 'w-1/12' }
                    ]}
                    GridTitle="Plataformas de busca"
                    LineDataPrint={(data) => {
                        return [
                            dateToString(data.cadastro),
                            data.numeroLicitacao,
                            data.contratante,
                            data.objeto,
                            data.valorOriginal,
                            data.plataformaOperacao?.nome,
                            translateStatus(data.status),
                        ];
                    }}
                    initialValues={{
                        cadastro: moment().format('YYYY-MM-DD')
                    }}
                    onRenderForm={(GridModes, SetFieldValue, values) => {
                        localStorage.setItem('autoEdit', null);
                        return (
                            <div className=''>
                                <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                    <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                </div>
                                <div className='p-2'>
                                    <GroupInput>
                                        <DateInput name="cadastro" label="Cadastro" className={'w-min'} />
                                        <Input name="numeroLicitacao" label="Número da licitação" className={'w-48'} />
                                        <SelectInput
                                            name="status"
                                            label="Status"
                                            defaultValue='1'
                                            listOptions={[
                                                { option: 'Pendente', value: 1 },
                                                { option: 'Operação', value: 2 },
                                                { option: 'Encerrado', value: 3 },
                                                { option: 'Adjudicado', value: 4 },
                                                { option: 'Anulado', value: 5 },
                                                { option: 'Revogado', value: 6 },
                                                { option: 'Desclassificado', value: 7 },
                                                { option: 'Habilitado', value: 8 },
                                                { option: 'Recurso', value: 9 },
                                            ]}
                                            className={'w-48'}
                                        />

                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="contratante" label="Contratante" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="USAG" label="Código USAG" className={'w-48'} />
                                        <Input name="cidade" label="Município" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="objeto" label="Objeto" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input type="number" name="quantidade" label="Quantidade" className={'w-32'} />
                                        <InputCurrency name="valorOriginal" label="Valor Original" className={'w-40'} />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="dataProposta" label="Data da proposta" className={'w-min'} />
                                        <TimeInput name="horarioLimiteProposta" label="Horário limite para envio de proposta" className={'w-64'} />
                                        <DateInput name="dataSessao" label="Data da Sessão" className={'w-min'} />
                                        <TimeInput name="horarioSessao" label="Horário da Sessão" className={'w-52'} />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="modalidade" label="Modalidade" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="criterioJulgamento" label="Critério de Julgamento" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DBSelectInput
                                            name="plataformaOperacaoID"
                                            label="Plataforma de Operação"
                                            url={'/licitaoperacaoactive'}
                                            displaySelectField="nome"
                                            className={'w-1/3'}
                                        />
                                    </GroupInput>
                                    <GroupInput>
                                        <InputCurrency name="valorProposta" label="Valor da Proposta" className={'w-48'} />
                                        <InputCurrency name="valorVencedor" label="Valor Vencedor" className={'w-48'} />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="contato" label="Contato" />
                                        <MaskInput name="telefone" label="Telefone" mask="(99) 9999-9999" className={'w-1/2'} />
                                    </GroupInput>
                                    <GroupInput>
                                        <MaskInput name="whatsapp" label="WhatsApp Suporte" mask="(99) 9 9999-9999" className={'w-1/2'} />
                                        <Input name='email' label='Email' />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input type="number" name="participantes" label="Participantes" className={'w-48'} />
                                    </GroupInput>
                                </div>
                            </div>
                        );
                    }}
                    validationSchema={yup.object().shape({
                        numeroLicitacao: yup.string().required('Número é obrigatório'),
                    })}
                    FormTabs={[
                        () => {
                            return <div>Gestão</div>;
                        },
                        () => {
                            return <div>Pontos Críticos</div>;
                        },
                        () => {
                            return <div>Checklist</div>;
                        },
                        () => {
                            return <div>Diário de Bordo</div>;
                        }
                        ,
                        () => {
                            return <div>Anexos</div>;
                        }
                    ]}
                    onRenderFormExtra={[
                        (GridModes, item) => {
                            return (
                                <div>
                                    {!GridModes.insert_mode && (
                                        <div className={'m-2'}>
                                            <GridPontosCriticos url={'/griddocument/criticos-' + item._id} perms={'10'} />
                                        </div>
                                    )}
                                </div>
                            );
                        },
                        (GridModes, item) => {
                            return (
                                <div>
                                    {!GridModes.insert_mode && (
                                        <div className={'m-2'}>
                                            <GridChecklist url={'/griddocument/checklist-' + item._id} perms={'10'} />
                                        </div>
                                    )}
                                </div>
                            );
                        },
                        (GridModes, item) => {
                            return (
                                <div>
                                    {!GridModes.insert_mode && (
                                        <div className={'m-2'}>
                                            <GridDiario url={'/griddocument/diariobordo-' + item._id} perms={'10'} />
                                        </div>
                                    )}
                                </div>
                            );
                        },
                        (GridModes, item) => {
                            return (
                                <div>
                                    <GridAnexos url={'/attachment/edital-' + item._id} perms={'41'} />
                                </div>
                            );
                        },
                    ]}
                    onBeforeDelete={async (item) => {
                        await System.apiDelete('/griddocument/diariobordo-' + item._id);
                        await System.apiDelete('/griddocument/checklist-' + item._id);
                        await System.apiDelete('/griddocument/criticos-' + item._id);
                        await System.apiDelete('/attachment/edital-' + item._id);
                    }}
                />
            </div>
        </div>
    );
}