import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { v4 as uuidv4 } from 'uuid';
import { GroupInput, Input } from '../components/Input';
import { useSystemContext } from '../Context/SystemContext';

import { translateStatus } from './LicitaEdital';
const moment = require('moment');
const yup = require('../interface/yup-br');




export const LicitaChart = () => {
    const System = useSystemContext();
    const [appSubmitForms, setAppSubmitForms] = useState([]);
    const [chartJSX, setChartJSX] = useState(null);
    const [periodoInicio, setPeriodoInicio] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
    const [periodoFinal, setPeriodoFinal] = useState(moment().format('YYYY-MM-DD'));


    useEffect(() => {
        (async () => {
            const response = await System.apiGet(`/licitaedital`);
            if (response.status === 200) setAppSubmitForms(response.data);
        })();
        // eslint-disable-next-line
    }, []);

    /**
     * 
     * @argument fnClickExecutar {function} funcao que gera o grafico ao clicar no Buscar dados
     * @returns JSX
     */
    const GetPeriodo = (fnClickExecutar) => {
        return (
            <Formik
                initialValues={{
                    inicio: periodoInicio,
                    final: periodoFinal,
                }}
                onSubmit={async (values) => {
                    setPeriodoInicio(values.inicio);
                    setPeriodoFinal(values.final);
                    setChartJSX(await fnClickExecutar(new Date(values.inicio), new Date(values.final)));
                }}
                validateOnChange={true}
                validateOnBlur={true}
                enableReinitialize={true}
                validationSchema={yup.object({
                    inicio: yup.date().required('Data inicial é obrigatória'),
                    final: yup.date().required('Data final é obrigatória'),
                })
                }
            >
                {({ values, errors, setErrors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form>
                        <GroupInput>
                            <Input
                                type="date"
                                label="Data Inicial"
                                key={uuidv4()}
                                name="inicio"
                                className="w-min"
                            />
                            <Input
                                type="date"
                                label="Data Final"
                                id={uuidv4()}
                                name="final"
                                className="w-min"
                            />
                            <div className="flex flex-col">
                                <button
                                    type="submit"
                                    className="px-4 py-2 text-white rounded-md mt-7 bg-amarelo-eq"
                                >
                                    Buscar dados
                                </button>
                            </div>
                        </GroupInput>
                    </Form>
                )}
            </Formik>
        );
    }


    /**
     * arguments inicio {Date} data inicial
     * arguments final {Date} data final
     * 
     */
    const Periodo = async (inicio, final) => {
        const dataMensal = [];
        appSubmitForms.forEach((item) => {
            const data = new Date(item.dataSessao);
            if (inicio <= data && final >= data) {
                const dataStr = moment(data).format('DD/MM');
                const existingData = dataMensal.find((itemMes) => itemMes.name === dataStr);
                if (existingData) {
                    existingData.uv += 1;
                } else {
                    dataMensal.push({ name: dataStr, uv: 1 });
                }
            }
        });

        dataMensal.sort((a, b) => a.name.localeCompare(b.name));

        const ticks = [];
        let highestUvItem = {};
        if (dataMensal.length > 0) {
            highestUvItem = dataMensal.reduce((max, item) => {
                if (!item.hasOwnProperty('uv')) {
                    console.error("Um item em dataMensal não possui a propriedade 'uv':", item);
                    return max;
                }
                return item.uv > max.uv ? item : max;
            }, dataMensal[0]);
        }
        let maxUV = highestUvItem.uv;

        maxUV += maxUV % 2 === 0 ? 2 : 3;
        for (let i = 0; i < maxUV; i += 2) ticks.push(i);

        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={150}
                    height={200}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    data={dataMensal}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis ticks={ticks} />
                    <Tooltip formatter={(value, name) => [value]} />
                    <Bar dataKey="uv" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        );
    }

    /**
     * Converts a string to a float after removing invalid characters.
     * @param {string} str - The string to convert.
     * @returns {number} - The converted float.
     */
    const convertStringToFloat = (str) => {
        // Remove any character that is not a digit, a dot, or a minus sign
        str = str.replace('.', '');
        str = str.replace(',', '.');
        const cleanedStr = str.replace(/[^0-9.-]/g, '');
        const float = parseFloat(cleanedStr);
        return isNaN(float) ? 0 : float;
    };

    /**
     * Formats a float number to Brazilian Real currency.
     * @param {number} value - The float number to format.
     * @returns {string} - The formatted currency string.
     */
    const formatToBRL = (value) => {
        return value.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
    };

    /**
     * arguments inicio {Date} data inicial
     * arguments final {Date} data final
     * 
     */
    const porStatus = (inicio, final) => {
        let dataStatus = [];
        appSubmitForms.forEach((item) => {
            const data = new Date(item.dataSessao);
            if (inicio <= data && final >= data) {
                const dataStr = translateStatus(item?.status);
                const existingData = dataStatus.find((item) => item.name === dataStr);
                if (existingData) {
                    let _uv = convertStringToFloat(item.valorContratacao) + convertStringToFloat(existingData.uv);
                    existingData.uv = _uv;
                    existingData.total = formatToBRL(_uv);
                } else {
                    dataStatus.push({ name: dataStr, uv: convertStringToFloat(item.valorContratacao), total: formatToBRL(convertStringToFloat(item.valorContratacao)) });
                }
            }
        });
        dataStatus = dataStatus.map((item) => {
            item.total = formatToBRL(item.uv);
            item.name = `${item.name} - ${item.total}`;
            return item;
        });

        let COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA00FF', '#FF00AA', '#00AAFF'];
        if (COLORS.length < dataStatus.length) {
            const additionalColors = Array(dataStatus.length - COLORS.length).fill().map(() => `#${Math.floor(Math.random() * 16777215).toString(16)}`);
            COLORS.push(...additionalColors);
        }

        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        };
        const CustomTooltip = ({ active, payload }) => {
            if (active && payload && payload.length) {
                const { name } = payload[0].payload; // Pode personalizar os dados conforme sua estrutura
                return (
                    <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
                        <p>{`${name}`}</p>
                    </div>
                );
            }
            return null;
        };


        return (
            <PieChart width={500} height={270}>
                <Pie
                    data={dataStatus}
                    cx={130}
                    cy={130}
                    labelLine={false}
                    innerRadius={0}
                    outerRadius={130}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="uv"
                    label={renderCustomizedLabel}
                >
                    {dataStatus.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend layout="vertical" align="right" verticalAlign="middle" />
            </PieChart>
        );
    }


    return (
        <div className='p-2 h-fit'>
            <Tabs onSelect={(index, last, event) => setChartJSX(null)}>
                <TabList>
                    <Tab key={uuidv4()}>
                        Periodo
                    </Tab>
                    <Tab key={uuidv4()}>
                        Status
                    </Tab>
                </TabList>
                <TabPanel key={uuidv4()}>
                    <div className='h-[650px] w-full'>
                        <span className='ml-2 text-xl font-semibold'>
                            Editais no Período
                        </span>
                        <div className='h-[350px]'>
                            {GetPeriodo(Periodo)}
                            {chartJSX}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel key={uuidv4()}>
                    <div className='h-[650px] w-full ml-4'>
                        <span className='ml-2 text-xl font-semibold'>
                            Editais agrupados por Status
                        </span>
                        <div className='h-[450px] pt-4 pl-4'>
                            {GetPeriodo(porStatus)}
                            {chartJSX}
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
        </div >);
}
